<template>
    <div>
        <section class="py-8 md:py-16">
        <div class="container max-w-screen-xl mx-auto px-4">
            <h1 
            data-aos="fade-down" data-aos-duration="500"
            class="font-semibold text-gray-700 text-3xl md:text-4xl text-center mb-5"
            >Who Are We?</h1>
            <p 
            data-aos="fade-up" data-aos-duration="500"
            class="font-normal text-gray-500 text-md md:text-lg text-center mb-20 md:mb-10"
            >
            <b 
            class="text-three">Nahil</b> is one of the classified and accredited establishments in the field of maintenance and implementation for all electrical, mechanical, and electronic works, and it started its activity since 2001.
            </p>
            <div class="grid max-w-screen-xl px-4 mx-auto lg:gap-8 xl:gap-0 lg:grid-cols-12">
                <div
                data-aos="fade-right" data-aos-duration="500"
                class="m-auto place-self-center lg:col-span-6">
                    <img src="../assets/img/hero_image.jpg" class="rounded-lg" alt="Image">
                </div>
                <div 
                data-aos="fade-left" data-aos-duration="500" class="m-auto place-self-center lg:col-span-6 mt-5 text-center sm:text-start lg:ms-5">
                    <h1 class="font-bold text-two text-3xl md:text-4xl mb-10"
                    >Our Vision</h1>
                    <p class="font-normal text-gray-600 text-sm md:text-lg mb-5"
                    >
                    Professionalism and efficiency in work, providing the best quality for all our clients according to international engineering standards.
                    </p>
                </div>
            </div>
            <div class="my-16"></div>
            
    
        </div>
        </section>
        <section class="bg-slate-50 rounded-xl py-8 md:py-16">
            <div class="grid m-5 max-w-screen-xl px-4 mx-auto lg:gap-8 xl:gap-0 lg:grid-cols-12">
                <div 
                data-aos="fade-right" data-aos-duration="500"
                class="m-auto place-self-center order-2 lg:order-1 lg:col-span-6 mt-5 text-center sm:text-start">
                    <h1 class="font-bold text-two text-3xl md:text-4xl mb-10"
                    >Our Mission</h1>
                    <p class="font-normal text-gray-600 text-sm md:text-lg mb-5"
                    >
                    In Nahil, we carry a societal message through which we aim to promote quality competitiveness in electromechanical works.
                    </p>
                </div>
                <div 
                data-aos="fade-left" data-aos-duration="500"
                class="m-auto place-self-center order-1 lg:order-2 lg:col-span-6">
                    <img src="../assets/img/hero_image.jpg" class="rounded-lg" alt="Image">
                </div>
            </div>
        </section>
    </div>
    </template>
    