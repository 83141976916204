<template>
    <div class="work">
        <section class="py-8 md:py-16">

            <div class="container max-w-screen-xl mx-auto px-4">

                <h1 data-aos="fade-down" data-aos-duration="500" class="font-semibold text-gray-700 text-3xl md:text-4xl text-center mb-5">
                    Our Work
                </h1>

                <p data-aos="fade-up" data-aos-duration="500" class="font-normal text-gray-500 text-md md:text-lg text-center mb-20">
                    Our company takes pride in offering innovative and advanced solutions to our esteemed clients. Discover what we can achieve together today and contact our team of experts to start your next project. Let's build a bright future for your business together.
                </p>

                <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 xl:gap-10">
                    <div v-for="(item, index) in items" :key="index" class="space-y-2 xl:space-y-4" data-aos="zoom-out" data-aos-duration="500">
                    <img src="../assets/img/hero_image.jpg" alt="Image" class="hover:opacity-75 transition ease-in-out duration-300 rounded-xl">
                    <h2 class="block font-semibold text-gray-700 text-xl md:text-2xl hover:text-green-500 transition ease-in-out duration-300">
                    {{ item.description }}
                    </h2>
                    <p class="font-normal text-gray-400 text-base">{{ item.name }}</p>
                    </div>
                </div>
                <div class="flex flex-wrap md:flex-nowrap justify-between my-8">
                    <div>
                        <p class="font-normal text-start text-gray-500 text-md md:text-lg mb-3"
                        >Contact Us</p>

                        <h1 class="font-bold text-gray-700 text-xl md:text-4xl">
                            You have a project ?
                        </h1>
                    </div>

                    <div class="mt-10">
                        <router-link to="/contact"
                            class="px-4 py-2 md:px-8 md:py-3 font-medium text-three text-lg border-2 border-three rounded-md hover:bg-three hover:text-white transition ease-linear duration-300">
                            Call Us Now
                        </router-link>
                    </div>
                </div>
                <hr class="text-gray-300 mb-8">
                <p class="font-normal text-gray-500 text-md md:text-lg mb-4 md:mb-10">
                    Contact us now and we will be happy to turn your ideas into reality and sustainable success
                </p>
            </div>
        </section>
    </div>
</template>
<script>
import { ref } from 'vue';

export default {
  setup() {
    const items = ref([
        { 
            imageSrc: "/assets/img/hero_image.jpg", 
            description: "إمارة منطقة المدينة المنورة - القصر الحكومي", 
            name: "تنفيذ الأعمال الإلكتروميكانيكية  (HVAC)" 
        },
        { 
            imageSrc: "/assets/img/hero_image.jpg", 
            description: "رئاسة امن الدولة - مبنى المباحث العامة - المدينة المنورة", 
            name: "تنفيذ الأعمال الإلكتروميكانيكية  (HVAC)" 
        },
        { 
            imageSrc: "/assets/img/hero_image.jpg", 
            description: "وزارة الداخلية - مبنى الأحوال المدنية -المدينة المنورة", 
            name: "تنفيذ الأعمال الإلكتروميكانيكية  (HVAC)" 
        },
    ]);
    return { items };
    }   
};
</script>
