<template>
    <div>
        <section class="py-8 md:py-16 bg-slate-50 rounded-lg m-5">
            <div class="container max-w-screen-xl mx-auto px-4">
                <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3">
                    <div data-aos="zoom-in" data-aos-duration="500" class="text-center mb-10 xl:mb-0">
                        <div class="flex items-center justify-center">
                            <div
                                class="w-20 py-7 flex justify-center bg-one text-white rounded-md mb-5 md:mb-10">
                                <svg fill="#fff" class="w-7 h-7 text-white" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" xmlns:xlink="http://www.w3.org/1999/xlink" enable-background="new 0 0 512 512">
                                    <path 
                                    stroke="currentColor" 
                                    d="m396.1,11h-238.9c-7.5-0.5-22.1,6.8-20.5,22.3l19.9,211.2c1,10.5 9.9,18.5 20.5,18.5h79v46.9c0,11.3 9.2,20.4 20.5,20.4 36,0 65.3,29.1 65.3,64.9s-29.3,64.9-65.3,64.9c-36,0-65.3-29.1-65.3-64.9 0-11.3-9.2-20.4-20.5-20.4-11.3,0-20.5,9.1-20.5,20.4 0,58.3 47.7,105.7 106.4,105.7 58.7,0 106.4-47.4 106.4-105.7 0-51.3-37-94.2-85.9-103.8v-28.4h79c10.6,0 19.5-8 20.5-18.5l19.9-211.2c0.8-6.8-3.2-21.6-20.5-22.3zm-38.6,211.2h-161.7l-16-170.4h193.8l-16.1,170.4z"/>
                                </svg>
                            </div>
                        </div>
                        <h2 class="font-semibold text-gray-700 text-2xl md:text-3xl mb-5"
                        >Long Experience</h2>
                        <p class="font-normal text-gray-400 text-lg md:text-lg">
                            Over 20 years in the contracting field, enabling us to provide the best for all our clients.                        </p>
                        </div>
                    <div data-aos="zoom-in" data-aos-duration="500" class="text-center mb-10 md:mb-0">
                        <div class="flex items-center justify-center">
                            <div
                                class="w-20 py-7 flex justify-center bg-two text-two rounded-md mb-5 md:mb-10">
                                <svg fill="#fff" class="w-7 h-7" viewBox="0 0 100 100" version="1.1" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                    <path d="M95,16H81v-4c0-0.6-0.4-1-1-1h-5V9c0-2.8-2.2-5-5-5h-3H57H43H33h-3c-2.8,0-5,2.2-5,5v2h-5c-0.6,0-1,0.4-1,1v4H5    c-0.6,0-1,0.4-1,1v10c0,0.6,0.4,1,1,1h14v4c0,0.6,0.4,1,1,1h5v2c0,2.8,2.2,5,5,5h2v1c0,2.8,2.2,5,5,5h1v6c0,3.3,2.7,6,6,6h1v4.9    l-0.4,0.1c-0.5,0.2-0.8,0.8-0.6,1.3c0.2,0.4,0.5,0.6,0.9,0.6v3.9l-0.4,0.1c-0.5,0.2-0.8,0.8-0.6,1.3c0.2,0.4,0.5,0.6,0.9,0.6v3.9    l-0.4,0.1c-0.5,0.2-0.8,0.8-0.6,1.3c0.2,0.4,0.5,0.6,0.9,0.6v3.9l-0.4,0.1c-0.5,0.2-0.8,0.8-0.6,1.3c0.2,0.4,0.5,0.6,0.9,0.6    c0.1,0,0.2,0,0.4-0.1l0.6-0.2l3.2,4.9c0.2,0.3,0.5,0.4,0.8,0.4s0.6-0.2,0.8-0.4l4-6c0.1-0.2,0.2-0.4,0.2-0.6v-1.9l0.4-0.1    c0.5-0.2,0.8-0.8,0.6-1.3C55.8,77.2,55.4,77,55,77v-3.9l0.4-0.1c0.5-0.2,0.8-0.8,0.6-1.3C55.8,71.2,55.4,71,55,71v-3.9l0.4-0.1    c0.5-0.2,0.8-0.8,0.6-1.3C55.8,65.2,55.4,65,55,65v-3.9l0.4-0.1c0.5-0.2,0.8-0.8,0.6-1.3C55.8,59.2,55.4,59,55,59v-1h1    c3.3,0,6-2.7,6-6v-6h1c2.8,0,5-2.2,5-5v-1h2c2.8,0,5-2.2,5-5v-2h5c0.6,0,1-0.4,1-1v-4h14c0.6,0,1-0.4,1-1V17    C96,16.4,95.6,16,95,16z M66,6v14h-8V6H66z M42,6v14h-8V6H42z M6,26v-8h13v8H6z M21,31v-4V17v-4h4v18H21z M53,80.7l-3,4.5    l-2.2-3.3l5.2-2.1V80.7z M53,77.7l-6,2.4v-3.8l6-2.4V77.7z M53,71.7l-6,2.4v-3.8l6-2.4V71.7z M53,65.7l-6,2.4v-3.8l6-2.4V65.7z     M53,59.7l-6,2.4V58h6V59.7z M60,52c0,2.2-1.8,4-4,4h-2h-8h-2c-2.2,0-4-1.8-4-4v-6h20V52z M66,41c0,1.7-1.3,3-3,3h-2H39h-2    c-1.7,0-3-1.3-3-3v-1h32V41z M73,35c0,1.7-1.3,3-3,3h-3H33h-3c-1.7,0-3-1.3-3-3v-3V12V9c0-1.7,1.3-3,3-3h2v15c0,0.6,0.4,1,1,1h10    c0.6,0,1-0.4,1-1V6h12v15c0,0.6,0.4,1,1,1h10c0.6,0,1-0.4,1-1V6h2c1.7,0,3,1.3,3,3v3v20V35z M79,31h-4V13h4v4v10V31z M94,26H81v-8    h13V26z"/>
                                    <path d="M95,74H58c-0.6,0-1,0.4-1,1v6.7l-7,9.6l-7-9.6V75c0-0.6-0.4-1-1-1H5c-0.6,0-1,0.4-1,1v20c0,0.6,0.4,1,1,1h90    c0.6,0,1-0.4,1-1V75C96,74.4,95.6,74,95,74z M94,94H6V76h35v6c0,0.2,0.1,0.4,0.2,0.6l8,11c0.4,0.5,1.2,0.5,1.6,0l8-11    c0.1-0.2,0.2-0.4,0.2-0.6v-6h35V94z"/>
                                    <path d="M44,54c0.6,0,1-0.4,1-1v-4c0-0.6-0.4-1-1-1s-1,0.4-1,1v4C43,53.6,43.4,54,44,54z"/>
                                    <path d="M48,54c0.6,0,1-0.4,1-1v-4c0-0.6-0.4-1-1-1s-1,0.4-1,1v4C47,53.6,47.4,54,48,54z"/>
                                    <path d="M52,54c0.6,0,1-0.4,1-1v-4c0-0.6-0.4-1-1-1s-1,0.4-1,1v4C51,53.6,51.4,54,52,54z"/>
                                    <path d="M56,54c0.6,0,1-0.4,1-1v-4c0-0.6-0.4-1-1-1s-1,0.4-1,1v4C55,53.6,55.4,54,56,54z"/>
                                    <path d="M67,26H33c-0.6,0-1,0.4-1,1v6c0,0.6,0.4,1,1,1h34c0.6,0,1-0.4,1-1v-6C68,26.4,67.6,26,67,26z M66,32H34v-4h32V32z"/>
                                </svg>
                            </div>
                        </div>
                        <h2 class="font-semibold text-gray-700 text-2xl md:text-3xl mb-5"
                        >Best Quality</h2>

                        <p class="font-normal text-gray-400 text-lg md:text-lg">
                            We rely on market study results, its requirements, and the project's capacity to provide the best quality.                        </p>
                    </div>
                    <div data-aos="zoom-in" data-aos-duration="500" class="text-center mb-10 md:mb-0">
                        <div class="flex items-center justify-center">
                            <div class="w-20 py-7 flex justify-center bg-three text-three rounded-md mb-5 md:mb-10">
                                <svg fill="#fff" class="w-10 h-w-10" viewBox="0 0 100 100" version="1.1" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                    <path d="M91,71h-1V58c0-8.4-3-16.5-8.5-22.8l1.6-3.8c0.3-0.7,0.3-1.6,0-2.3c-0.3-0.7-0.9-1.3-1.6-1.6l-3.7-1.5    c-1.5-0.6-3.3,0.1-3.9,1.6l-0.4,0.9c-5-3.1-10.6-4.9-16.5-5.2V22c0-1.7-1.3-3-3-3h-8c-1.7,0-3,1.3-3,3v1.1    c-5.9,0.3-11.5,2.1-16.5,5.2l-0.4-0.9c-0.6-1.5-2.4-2.2-3.9-1.6l-3.7,1.5c-0.7,0.3-1.3,0.9-1.6,1.6c-0.3,0.7-0.3,1.6,0,2.3    l1.6,3.8C13,41.5,10,49.6,10,58v13H9c-2.8,0-5,2.2-5,5s2.2,5,5,5h82c2.8,0,5-2.2,5-5S93.8,71,91,71z M77,27.7l3.7,1.5    c0.2,0.1,0.4,0.3,0.5,0.5c0.1,0.2,0.1,0.5,0,0.8l-1.8,4.4c0,0,0,0,0,0l-5.8,13.8L68,46.4l7.7-18.2C75.9,27.7,76.5,27.5,77,27.7z     M50,43c6.1,0,11,4.9,11,11s-4.9,11-11,11s-11-4.9-11-11S43.9,43,50,43z M45,22c0-0.6,0.4-1,1-1h8c0.6,0,1,0.4,1,1v20    c-1.5-0.6-3.2-1-5-1s-3.5,0.4-5,1V22z M18.7,29.8c0.1-0.2,0.3-0.4,0.5-0.5l3.7-1.5c0.1-0.1,0.3-0.1,0.4-0.1c0.4,0,0.8,0.2,0.9,0.6    L32,46.4l-5.6,2.3l-5.8-13.8c0,0,0,0,0,0l-1.8-4.4C18.6,30.3,18.6,30,18.7,29.8z M12,58c0-7.6,2.6-14.9,7.4-20.8l5.6,13.2    c0.2,0.4,0.5,0.6,0.9,0.6c0.1,0,0.3,0,0.4-0.1l7.4-3.1c0.2-0.1,0.4-0.3,0.5-0.5c0.1-0.2,0.1-0.5,0-0.8l-6.9-16.4    c4.7-3,10.1-4.7,15.7-5.1v18c-3.6,2.3-6,6.3-6,10.9c0,7.2,5.8,13,13,13s13-5.8,13-13c0-4.6-2.4-8.6-6-10.9v-18    c5.6,0.3,11,2.1,15.7,5.1l-6.9,16.4c-0.1,0.2-0.1,0.5,0,0.8c0.1,0.2,0.3,0.4,0.5,0.5l7.4,3.1C73.9,51,74,51,74.1,51    c0.4,0,0.8-0.2,0.9-0.6l5.6-13.2C85.4,43.1,88,50.4,88,58v13H12V58z M91,79H9c-1.7,0-3-1.3-3-3s1.3-3,3-3h2h78h2c1.7,0,3,1.3,3,3    S92.7,79,91,79z"/>
                                    <path d="M50,63c5,0,9-4,9-9s-4-9-9-9s-9,4-9,9S45,63,50,63z M50,47c3.9,0,7,3.1,7,7s-3.1,7-7,7s-7-3.1-7-7S46.1,47,50,47z"/>
                                </svg>
                            </div>
                        </div>
                        <h2 class="font-semibold text-gray-700 text-2xl md:text-3xl mb-5">
                            Customer Service
                        </h2>
                        <p class="font-normal text-gray-400 text-lg md:text-lg"
                        >
                        Attention is the essence of all relationships in our company, leading to enhancing customer satisfaction levels.
                    </p>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>