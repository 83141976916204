<template>
  <div class="about">
    <About/>
    <Values/>
  </div>
</template>
<script>
import { ref } from 'vue';
import Values from '@/components/Values.vue'
import About from '@/components/About.vue'
export default {
  components:{
    Values,
    About,
  },
  setup(){

    return {
      
    }
  }
}


</script>