<template>
    <div class="contact">
        <section class="px-4 py-12 md:py-16 sm:px-6 lg:px-8">
                <div class="max-w-screen-xl mx-auto">
                <div class="grid lg:grid-cols-2 lg:gap-x-8 xl:gap-x-16">
                    <div
                    class="flex flex-col items-start justify-between sm:items-center lg:items-start"
                    >
                    <div>
                        <div
                        class="w-full mt-4 text-left md:mt-5 sm:text-center lg:text-left"
                        >
                        <h1 data-aos="fade-up" data-aos-duration="500"
                            class="text-4xl h-11 sm:h-24 text-transparent bg-clip-text bg-gradient-to-r from-one to-four text-center md:text-center sm:text-start font-extrabold sm:text-5xl md:text-6xl"
                        >
                        Contact Us
                        </h1>
                        <p
                            data-aos="fade-up" data-aos-duration="800"
                            class="max-w-lg text-center sm:text-start mx-auto mt-3 text-xl md:mt-5 text-gray-500 dark:text-gray-200 sm:max-w-2xl"
                        >
                        We love to hear from you. Get in touch with us and we'll get back to you as soon as possible.
                        </p>
                        </div>
                    </div>
                    <div
                        class="grid gap-6 w-full mt-8 sm:mt-10 lg:mt-0 sm:grid-cols-2 sm:grid-rows-2 sm:gap-12"
                    >
                        <div data-aos="zoom-out" data-aos-duration="500" class="mt-4">
                        <h5 class="text-lg font-bold text-gray-800 dark:text-gray-100">
                            Kingdom of Saudi Arabia, The Medina</h5>
                        <p class="mt-1 text-base text-gray-500 dark:text-gray-100">
                            Kingdom of Saudi Arabia, The Medina
                        </p>
                        </div>
                        <!-- Location 2 -->
                        <div data-aos="zoom-out" data-aos-duration="750" class="mt-4">
                        <h5 class="text-lg font-bold text-gray-800 dark:text-gray-100">
                            Call Us
                        </h5>
                        <p class="mt-1 text-base text-gray-500 dark:text-gray-200" dir="ltr">
                            +966 014 8260 300
                            <br />+966 55 700 0098
                        </p>
                        </div>

                        <!-- Location 3 -->
                        <div data-aos="zoom-out" data-aos-duration="1000">
                            <h5 class="text-lg font-bold text-gray-800 dark:text-white">
                                Working Hours
                            </h5>
                            <p class="mt-1 text-base text-gray-500 dark:text-gray-200">
                                Sunday to Thursday
                                <br /> from 9:00 AM to 8:00 PM.
                            </p>
                        </div>


                        <!-- Location 4 -->
                        <div data-aos="zoom-out" data-aos-duration="1250">
                        <h5 class="text-lg font-bold text-gray-800 dark:text-white">
                            Email Us
                        </h5>
                        <p class="mt-1 text-base text-gray-500 dark:text-gray-200">
                                info@nahil.sa.com
                            <br />
                        </p>
                        </div>
                    </div>
                    <div class="w-full mt-8 sm:mt-10 lg:mt-0">
                        <h6 class="text-lg font-semibold text-center text-gray-600 dark:text-gray-100" >
                            Follow Us
                        </h6>
                        <div data-aos="zoom-in" dir="ltr"
                        class="flex justify-center mt-3 space-x-4" >
                        <a class="flex items-center justify-center w-12 h-12 transition duration-300 ease-in-out rounded-full bg-gradient-to-r dark:from-one dark:to-one from-one to-four text-white"
                            href="" >
                            <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="w-6 h-6"
                            width="44"
                            height="44"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round" >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <rect x="4" y="4" width="16" height="16" rx="4" />
                            <circle cx="12" cy="12" r="3" />
                            <line x1="16.5" y1="7.5" x2="16.5" y2="7.501" />
                            </svg>
                        </a>
                        <a class="flex items-center justify-center w-12 h-12 transition duration-300 ease-in-out rounded-full bg-gradient-to-r dark:from-secondary-600 dark:to-dark-700 from-one to-four text-white"
                            href="">
                            <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="w-6 h-6 text-white"
                            width="44"
                            height="44"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path
                                d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3"
                            />
                            </svg>
                        </a>
                        </div>
                    </div>
                    </div>
                    <div class="w-full mt-12 lg:mt-0">
                        <h1 data-aos="fade-up" data-aos-duration="500"
                            class="text-3xl text-center font-extrabold text-gray-800 dark:text-white"
                        >
                        Our Location on Google Map
                        </h1>
                        <div class="relative">
                        <div
                            class="absolute -inset-1 rounded-lg bg-gradient-to-r from-one via-two to-four opacity-25 blur"
                        ></div>
                        <div
                            class="relative"
                        >
                            <iframe
                            class="w-full mt-12 rounded-2xl object-cover h-96 shadow-lg"
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14528.282671585848!2d39.6216239!3d24.4483334!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15bdbfbb56bbeced%3A0x282d7f96be645f3b!2z2LTYsdmD2Kkg2YbYp9mH2YQg2YTZhNmF2YLYp9mI2YTYp9iqINin2YTZg9mH2LHZiNmF2YrZg9in2YbZitmD2YrYqQ!5e0!3m2!1sen!2s!4v1711821753361!5m2!1sen!2s" 
                            loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                        </div>


                    </div>
                </div>
                </div>
            </section>    </div>
</template>