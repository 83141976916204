<template>
    <div>
        <section class="bg-white dark:bg-gray-900">
            <div class="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
                <div class="m-auto place-self-center lg:col-span-7">
                    <h1
                        data-aos="fade-left" data-aos-duration="500"
                        class="max-w-2xl mb-4 text-5xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl dark:text-white">
                        <span class="text-transparent bg-clip-text bg-gradient-to-r from-one to-four font-bold">Nahil</span> Electromechanical Contracting Company
                    </h1>
                    <p 
                    data-aos="fade-right" data-aos-duration="600" 
                    class="max-w-2xl mb-6 text-lg font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
                        In Nahil, we carry a societal message through which we aim to promote a culture of quality in the electromechanical field.
                    </p>

                    <router-link to="/services"
                        data-aos="fade-up" data-aos-duration="800" 
                        class="inline-block w-48 mx-2 my-1 items-center justify-center px-5 py-3 text-base 
                        text-center border border-three rounded-md transition-all duration-500
                        hover:bg-three  text-three font-semibold hover:text-white
                        focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-gray-700 
                        dark:hover:bg-gray-700 dark:focus:ring-gray-800">
                        Our Services
                    </router-link>
                    <router-link to="/contact"
                        data-aos="fade-up" data-aos-duration="800" 
                        class="inline-block w-48 mx-2 my-1 items-center justify-center px-5 py-3 text-base 
                        font-semibold text-center text-gray-900 border border-gray-300 rounded-md 
                        hover:bg-gray-100 transition-all duration-500
                        focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-gray-700 
                        dark:hover:bg-gray-700 dark:focus:ring-gray-800">
                        Contact Us
                    </router-link>
                </div>
                <div class="relative mt-10 lg:mt-0 lg:col-span-5 lg:flex">
                    <span class="absolute inset-0 flex items-center justify-center">
                        <span 
                        class="animate-ping inline-flex h-52 w-52 rounded-full bg-gradient-to-r from-one to-four opacity-75"
                        style="animation-duration: 4s;"
                        ></span>
                    </span>
                    <img data-aos="zoom-out-up" data-aos-duration="1000"  src="../assets/img/buildings.png" class="w-full relative z-10" alt="mockup">
                </div>
            </div>
        </section>
    </div>
</template>
