<template>
    <div>
        <nav class="bg-white border-gray-200 dark:bg-gray-900 fixed w-full z-20 top-0 start-0 border-b">
            <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
                <router-link to="/" class="flex items-center space-x-3 rtl:space-x-reverse">
                    <img src="../assets/img/logos/logo-text.png" class="h-12" alt="Nahil Logo" />
                </router-link>
                <button data-collapse-toggle="navbar-default" type="button"
                    class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                    aria-controls="navbar-default" aria-expanded="false">
                    <span class="sr-only">Open main menu</span>
                    <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                        viewBox="0 0 17 14">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M1 1h15M1 7h15M1 13h15" />
                    </svg>
                </button>
                <div class="hidden w-full md:block md:w-auto" id="navbar-default">
                    <ul
                        class="font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
                        <li v-for="(item, index) in menuItems" :key="index">
                            <router-link v-if="item.to" :to="item.to" 
                            class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 
                            md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 
                            dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 
                            dark:hover:text-white md:dark:hover:bg-transparent"
                                :aria-current="item.ariaCurrent">{{ item.label }}</router-link>
                            <a v-else :href="item.href" :class="item.class">{{ item.label }}</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </div>
</template>

<script>
import { ref } from 'vue';
export default {
    setup() {
        const menuItems = ref([
            {
                label: 'Home',
                to: '/',
                ariaCurrent: 'page'
            },
            {
                label: 'About Nahil',
                to: '/about',
            },
            {
                label: 'Services',
                to: '/services',
            },
            {
                label: 'Our Work',
                to: '/work',
            },
            {
                label: 'Contact Us',
                to: '/contact',
            }
        ])

        return {
            menuItems,
        }
    }
};
</script>
