<template>
  <div>
    <Hero/>
    <Values/>
    <About/>
    <WhyUs/>
    <ServicesView/>
  </div>
</template>

<script>
import { ref } from 'vue';
import Hero from '@/components/Hero.vue'
import Values from '@/components/Values.vue'
import About from '@/components/About.vue'
import WhyUs from '@/components/WhyUs.vue'
import ServicesView from './ServicesView.vue';
export default {
  components:{
    Hero,
    Values,
    About,
    WhyUs,
    ServicesView,
  },
  setup(){

    return {

    }
  }
}


</script>