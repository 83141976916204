<template>
    <div class="services py-8 md:py-16 m-5">
        <h1 
                data-aos="fade-down" data-aos-duration="500"
                class="font-semibold text-gray-700 text-3xl md:text-4xl text-center mb-5">
                    Our Services
                </h1>

                <p 
                data-aos="fade-up"
                class="font-normal text-gray-500 text-md md:text-lg text-center mb-20">
                We offer you a wide range of engineering services and consultations to meet all your requirements.
                </p>


        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 xl:gap-10">
            <div data-aos="zoom-in" data-aos-duration="500" class="bg-white image-container image-one rounded w-full h-auto shadow-2xl p-8 hover:text-white duration-300"
                >
                <svg class="icon h-24 w-24 m-auto" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512" xml:space="preserve">
                                    <path d="M335.004,211.97c-2.745-3.403-7.73-3.938-11.133-1.193c-5.126,4.133-10.783,4.579-12.976,4.579
                                        c-6.584,0-11.269-3.204-12.977-4.581c-3.405-2.744-8.388-2.207-11.133,1.195c-2.744,3.405-2.208,8.389,1.195,11.133
                                        c3.747,3.02,11.706,8.088,22.915,8.088c4.813,0,14.188-1.051,22.917-8.089C337.215,220.358,337.749,215.374,335.004,211.97z"/>
                                        <path d="M359.456,175.241h-5.278c-4.373,0-7.917,3.545-7.917,7.917c0,4.373,3.545,7.918,7.917,7.918h5.278
                                        c4.373,0,7.918-3.545,7.918-7.918C367.373,178.786,363.828,175.241,359.456,175.241z"/>
                                        <path d="M267.612,175.241h-5.278c-4.373,0-7.918,3.545-7.918,7.917c0,4.373,3.545,7.918,7.918,7.918h5.278
                                        c4.373,0,7.918-3.545,7.918-7.918C275.53,178.786,271.985,175.241,267.612,175.241z"/>
                                        <path d="M491.942,338.293v-81.237c0-17.172-13.971-31.142-31.142-31.142h-46.449c-4.887,0-8.689,4.588-7.779,9.392l6.227,32.834
                                        h-67.015c11.642-3.62,22.7-9.077,32.671-16.092c9.867-6.943,18.661-15.395,25.99-24.979c4.719-6.173,8.834-12.819,12.25-19.802
                                        c3.856,1.837,8.066,2.812,12.436,2.812c16.008,0,29.031-13.023,29.031-29.031c0-16.008-13.023-29.032-29.031-29.032
                                        c-0.197,0-0.395,0.011-0.592,0.015c-0.011-0.357-0.024-0.714-0.038-1.07h13.298c4.373,0,7.917-3.545,7.917-7.918V121.93
                                        c0-4.373-3.545-7.918-7.917-7.918h-15.014c-8.008-45.276-42.375-82.201-86.858-93.507V7.918c0-4.373-3.545-7.918-7.918-7.918
                                        h-42.227c-4.373,0-7.917,3.545-7.917,7.918v12.588c-44.484,11.306-78.85,48.232-86.858,93.507h-15.014
                                        c-4.373,0-7.918,3.545-7.918,7.918v21.113c0,4.373,3.545,7.918,7.918,7.918h13.298c-0.014,0.357-0.027,0.714-0.038,1.07
                                        c-0.197-0.004-0.395-0.015-0.592-0.015c-16.008,0-29.031,13.023-29.031,29.031s13.023,29.031,29.031,29.031
                                        c4.369,0,8.579-0.975,12.436-2.812c5.741,11.734,13.438,22.478,22.681,31.706c8.557,8.541,18.418,15.768,29.154,21.33
                                        c6.111,3.167,12.502,5.795,19.075,7.838h-60.121c-16.44,0-31.107,10.244-36.934,25.336H45.922
                                        c-13.098,0-23.753,10.655-23.753,23.753v85.509c0,4.373,3.545,7.918,7.918,7.918c4.373,0,7.918-3.545,7.918-7.918v-64.22h199.522
                                        v123.865H38.004v-17.419c0-4.373-3.545-7.918-7.918-7.918c-4.373,0-7.918,3.545-7.918,7.918v17.419H8.973
                                        c-4.373,0-7.918,3.545-7.918,7.917v17.946C1.056,501.345,11.711,512,24.808,512h225.913c0.235,0,0.469-0.011,0.702-0.018
                                        c0.118,0.005,0.234,0.018,0.354,0.018h105.567c4.373,0,7.917-3.545,7.917-7.917c0-4.373-3.545-7.918-7.917-7.918h-84.232
                                        c0.879-2.478,1.362-5.142,1.362-7.918v-17.946c0-4.373-3.545-7.917-7.918-7.917h-13.196V317.229
                                        c0-13.098-10.655-23.753-23.753-23.753h-32.703c4.397-5.831,11.365-9.501,18.979-9.501h57.223l10.783,41.334l-10.445,125.332
                                        c-0.221,2.653,0.906,5.239,2.998,6.884l29.559,23.225c2.838,2.23,6.945,2.23,9.784,0l29.559-23.225
                                        c2.092-1.645,3.22-4.231,2.998-6.884l-10.445-125.332l10.785-41.334h67.12l10.364,54.646
                                        c-10.134,2.71-17.621,11.968-17.621,22.946v59.118c0,12.205,9.254,22.285,21.113,23.603v51.878h-30.087
                                        c-4.373,0-7.918,3.545-7.918,7.918c0,4.373,3.545,7.917,7.918,7.917h38.004c4.373,0,7.918-3.545,7.918-7.917v-12.945
                                        c4.521,2.534,9.743,3.971,15.307,3.971c15.74,0,28.759-11.46,30.849-26.888c0.191-0.682,0.293-1.401,0.293-2.143v-22.119
                                        c10.829-2.206,19.002-11.803,19.002-23.274v-59.118C510.944,350.096,502.771,340.499,491.942,338.293z M258.639,478.219v10.029
                                        c0,4.366-3.551,7.918-7.917,7.918H24.808c-4.366,0-7.917-3.551-7.917-7.918v-10.029H258.639z M229.608,309.311
                                        c0.546,0,1.079,0.056,1.594,0.162c3.605,0.74,6.323,3.937,6.323,7.756v5.455H38.004v-5.455c0-3.819,2.719-7.017,6.323-7.756
                                        c0.515-0.106,1.048-0.162,1.594-0.162H229.608z M407.489,188.095v-31.912c1.751-0.68,3.5-1.373,5.24-2.09
                                        c0.008,0.539,0.038,1.08,0.038,1.618C412.767,167.027,410.91,177.917,407.489,188.095z M429.129,167.852
                                        c7.278,0,13.197,5.919,13.197,13.196s-5.919,13.196-13.196,13.196c-2.29,0-4.484-0.584-6.442-1.685
                                        c2.605-7.885,4.403-16.133,5.284-24.65C428.356,167.876,428.741,167.852,429.129,167.852z M339.926,36.925
                                        c35.706,10.623,63.209,40.491,70.745,77.087h-70.745V36.925z M297.699,15.835h26.392v98.177h-26.392V15.835z M192.66,194.243
                                        c-7.277,0-13.196-5.919-13.196-13.196s5.919-13.196,13.196-13.196c0.387,0,0.773,0.024,1.157,0.057
                                        c0.88,8.517,2.678,16.765,5.284,24.65C197.144,193.66,194.95,194.243,192.66,194.243z M214.301,188.095
                                        c-3.421-10.178-5.278-21.068-5.278-32.384c0-0.538,0.031-1.079,0.038-1.618c1.741,0.718,3.488,1.411,5.24,2.091V188.095z
                                        M187.909,135.126v-5.278h42.755c4.373,0,7.918-3.545,7.918-7.917c0-4.373-3.545-7.918-7.918-7.918h-19.545
                                        c7.535-36.596,35.039-66.464,70.745-77.087v77.087h-17.419c-4.373,0-7.918,3.545-7.918,7.918c0,4.373,3.545,7.917,7.918,7.917
                                        H433.88v5.278H187.909z M371.653,150.961c-19.736,4.899-40.05,7.39-60.758,7.39c-20.707,0-41.022-2.49-60.758-7.39H371.653z
                                        M310.895,464.455l-21.314-16.747l9.597-115.172h23.434l9.597,115.172L310.895,464.455z M323.78,316.701h-25.77l-8.537-32.726
                                        h42.844L323.78,316.701z M310.895,257.583c-32.852,0-62.118-15.637-80.759-39.853V161.78c25.993,8.24,53.052,12.405,80.759,12.405
                                        c27.707,0,54.766-4.166,80.759-12.405v55.951C373.013,241.947,343.747,257.583,310.895,257.583z M460.8,241.748
                                        c8.44,0,15.307,6.867,15.307,15.307s-6.867,15.307-15.307,15.307c-8.44,0-15.307-6.867-15.307-15.307
                                        S452.36,241.748,460.8,241.748z M445.493,284.16c4.525,2.565,9.746,4.038,15.307,4.038c5.561,0,10.783-1.473,15.307-4.038v53.654
                                        h-30.614V284.16z M423.911,241.748h9.785c-2.565,4.525-4.038,9.746-4.038,15.307v14.995L423.911,241.748z M476.107,464.823
                                        c-0.029,0.178-0.052,0.36-0.068,0.545c-0.715,7.928-7.266,13.906-15.24,13.906c-7.973,0-14.525-5.978-15.24-13.906
                                        c-0.017-0.183-0.039-0.363-0.068-0.542v-20.389h30.614V464.823z M495.109,420.685c0,4.366-3.551,7.917-7.918,7.917h-54.895
                                        c-4.188,0-7.618-3.27-7.891-7.39h17.392c4.373,0,7.917-3.545,7.917-7.918s-3.545-7.918-7.917-7.918h-17.419v-5.278h17.419
                                        c4.373,0,7.917-3.545,7.917-7.918s-3.545-7.918-7.917-7.918h-17.419v-5.278h17.419c4.373,0,7.917-3.545,7.917-7.918
                                        s-3.545-7.918-7.917-7.918h-17.419v-1.583c0-4.366,3.551-7.918,7.918-7.918h54.895c4.366,0,7.918,3.551,7.918,7.918V420.685z"/>
                                        <path d="M198.994,432.825H76.008v-72.313c0-4.373-3.545-7.918-7.918-7.918c-4.373,0-7.917,3.545-7.917,7.918v80.231
                                        c0,4.373,3.545,7.917,7.917,7.917h130.903c4.373,0,7.918-3.545,7.918-7.917C206.911,436.37,203.366,432.825,198.994,432.825z"/>
                                        <path d="M195.874,365.208c-3.236-2.941-8.244-2.703-11.185,0.533l-17.079,18.787l-15.748-7.874
                                        c-2.909-1.456-6.417-0.982-8.837,1.196l-15,13.5l-15.848-19.018c-3.704-4.444-11.02-3.397-13.336,1.895l-14.779,33.781
                                        c-2.245,5.135,1.625,11.095,7.249,11.095c3.05,0,5.957-1.773,7.258-4.746l9.645-22.044l12.912,15.494
                                        c2.831,3.398,8.087,3.778,11.379,0.816l17.103-15.393l16.287,8.143c3.172,1.586,7.014,0.869,9.4-1.756l21.113-23.225
                                        C199.349,373.157,199.11,368.15,195.874,365.208z"/>

                </svg>
                <h1 class="text-xl font-bold my-7">
                    Construction and Building
                </h1>
                <p class="w-full">
                    Boundless Construction Company offers the best high-quality construction services with a 10-year guarantee on structural works.
                </p>
            </div>
            <div data-aos="zoom-in" data-aos-duration="500" class="bg-white image-container image-two rounded w-full h-auto shadow-2xl p-8 hover:text-white duration-300"
                >
                <svg class="icon h-24 w-24 m-auto" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512" xml:space="preserve">
                                    <path d="M335.004,211.97c-2.745-3.403-7.73-3.938-11.133-1.193c-5.126,4.133-10.783,4.579-12.976,4.579
                                        c-6.584,0-11.269-3.204-12.977-4.581c-3.405-2.744-8.388-2.207-11.133,1.195c-2.744,3.405-2.208,8.389,1.195,11.133
                                        c3.747,3.02,11.706,8.088,22.915,8.088c4.813,0,14.188-1.051,22.917-8.089C337.215,220.358,337.749,215.374,335.004,211.97z"/>
                                        <path d="M359.456,175.241h-5.278c-4.373,0-7.917,3.545-7.917,7.917c0,4.373,3.545,7.918,7.917,7.918h5.278
                                        c4.373,0,7.918-3.545,7.918-7.918C367.373,178.786,363.828,175.241,359.456,175.241z"/>
                                        <path d="M267.612,175.241h-5.278c-4.373,0-7.918,3.545-7.918,7.917c0,4.373,3.545,7.918,7.918,7.918h5.278
                                        c4.373,0,7.918-3.545,7.918-7.918C275.53,178.786,271.985,175.241,267.612,175.241z"/>
                                        <path d="M491.942,338.293v-81.237c0-17.172-13.971-31.142-31.142-31.142h-46.449c-4.887,0-8.689,4.588-7.779,9.392l6.227,32.834
                                        h-67.015c11.642-3.62,22.7-9.077,32.671-16.092c9.867-6.943,18.661-15.395,25.99-24.979c4.719-6.173,8.834-12.819,12.25-19.802
                                        c3.856,1.837,8.066,2.812,12.436,2.812c16.008,0,29.031-13.023,29.031-29.031c0-16.008-13.023-29.032-29.031-29.032
                                        c-0.197,0-0.395,0.011-0.592,0.015c-0.011-0.357-0.024-0.714-0.038-1.07h13.298c4.373,0,7.917-3.545,7.917-7.918V121.93
                                        c0-4.373-3.545-7.918-7.917-7.918h-15.014c-8.008-45.276-42.375-82.201-86.858-93.507V7.918c0-4.373-3.545-7.918-7.918-7.918
                                        h-42.227c-4.373,0-7.917,3.545-7.917,7.918v12.588c-44.484,11.306-78.85,48.232-86.858,93.507h-15.014
                                        c-4.373,0-7.918,3.545-7.918,7.918v21.113c0,4.373,3.545,7.918,7.918,7.918h13.298c-0.014,0.357-0.027,0.714-0.038,1.07
                                        c-0.197-0.004-0.395-0.015-0.592-0.015c-16.008,0-29.031,13.023-29.031,29.031s13.023,29.031,29.031,29.031
                                        c4.369,0,8.579-0.975,12.436-2.812c5.741,11.734,13.438,22.478,22.681,31.706c8.557,8.541,18.418,15.768,29.154,21.33
                                        c6.111,3.167,12.502,5.795,19.075,7.838h-60.121c-16.44,0-31.107,10.244-36.934,25.336H45.922
                                        c-13.098,0-23.753,10.655-23.753,23.753v85.509c0,4.373,3.545,7.918,7.918,7.918c4.373,0,7.918-3.545,7.918-7.918v-64.22h199.522
                                        v123.865H38.004v-17.419c0-4.373-3.545-7.918-7.918-7.918c-4.373,0-7.918,3.545-7.918,7.918v17.419H8.973
                                        c-4.373,0-7.918,3.545-7.918,7.917v17.946C1.056,501.345,11.711,512,24.808,512h225.913c0.235,0,0.469-0.011,0.702-0.018
                                        c0.118,0.005,0.234,0.018,0.354,0.018h105.567c4.373,0,7.917-3.545,7.917-7.917c0-4.373-3.545-7.918-7.917-7.918h-84.232
                                        c0.879-2.478,1.362-5.142,1.362-7.918v-17.946c0-4.373-3.545-7.917-7.918-7.917h-13.196V317.229
                                        c0-13.098-10.655-23.753-23.753-23.753h-32.703c4.397-5.831,11.365-9.501,18.979-9.501h57.223l10.783,41.334l-10.445,125.332
                                        c-0.221,2.653,0.906,5.239,2.998,6.884l29.559,23.225c2.838,2.23,6.945,2.23,9.784,0l29.559-23.225
                                        c2.092-1.645,3.22-4.231,2.998-6.884l-10.445-125.332l10.785-41.334h67.12l10.364,54.646
                                        c-10.134,2.71-17.621,11.968-17.621,22.946v59.118c0,12.205,9.254,22.285,21.113,23.603v51.878h-30.087
                                        c-4.373,0-7.918,3.545-7.918,7.918c0,4.373,3.545,7.917,7.918,7.917h38.004c4.373,0,7.918-3.545,7.918-7.917v-12.945
                                        c4.521,2.534,9.743,3.971,15.307,3.971c15.74,0,28.759-11.46,30.849-26.888c0.191-0.682,0.293-1.401,0.293-2.143v-22.119
                                        c10.829-2.206,19.002-11.803,19.002-23.274v-59.118C510.944,350.096,502.771,340.499,491.942,338.293z M258.639,478.219v10.029
                                        c0,4.366-3.551,7.918-7.917,7.918H24.808c-4.366,0-7.917-3.551-7.917-7.918v-10.029H258.639z M229.608,309.311
                                        c0.546,0,1.079,0.056,1.594,0.162c3.605,0.74,6.323,3.937,6.323,7.756v5.455H38.004v-5.455c0-3.819,2.719-7.017,6.323-7.756
                                        c0.515-0.106,1.048-0.162,1.594-0.162H229.608z M407.489,188.095v-31.912c1.751-0.68,3.5-1.373,5.24-2.09
                                        c0.008,0.539,0.038,1.08,0.038,1.618C412.767,167.027,410.91,177.917,407.489,188.095z M429.129,167.852
                                        c7.278,0,13.197,5.919,13.197,13.196s-5.919,13.196-13.196,13.196c-2.29,0-4.484-0.584-6.442-1.685
                                        c2.605-7.885,4.403-16.133,5.284-24.65C428.356,167.876,428.741,167.852,429.129,167.852z M339.926,36.925
                                        c35.706,10.623,63.209,40.491,70.745,77.087h-70.745V36.925z M297.699,15.835h26.392v98.177h-26.392V15.835z M192.66,194.243
                                        c-7.277,0-13.196-5.919-13.196-13.196s5.919-13.196,13.196-13.196c0.387,0,0.773,0.024,1.157,0.057
                                        c0.88,8.517,2.678,16.765,5.284,24.65C197.144,193.66,194.95,194.243,192.66,194.243z M214.301,188.095
                                        c-3.421-10.178-5.278-21.068-5.278-32.384c0-0.538,0.031-1.079,0.038-1.618c1.741,0.718,3.488,1.411,5.24,2.091V188.095z
                                        M187.909,135.126v-5.278h42.755c4.373,0,7.918-3.545,7.918-7.917c0-4.373-3.545-7.918-7.918-7.918h-19.545
                                        c7.535-36.596,35.039-66.464,70.745-77.087v77.087h-17.419c-4.373,0-7.918,3.545-7.918,7.918c0,4.373,3.545,7.917,7.918,7.917
                                        H433.88v5.278H187.909z M371.653,150.961c-19.736,4.899-40.05,7.39-60.758,7.39c-20.707,0-41.022-2.49-60.758-7.39H371.653z
                                        M310.895,464.455l-21.314-16.747l9.597-115.172h23.434l9.597,115.172L310.895,464.455z M323.78,316.701h-25.77l-8.537-32.726
                                        h42.844L323.78,316.701z M310.895,257.583c-32.852,0-62.118-15.637-80.759-39.853V161.78c25.993,8.24,53.052,12.405,80.759,12.405
                                        c27.707,0,54.766-4.166,80.759-12.405v55.951C373.013,241.947,343.747,257.583,310.895,257.583z M460.8,241.748
                                        c8.44,0,15.307,6.867,15.307,15.307s-6.867,15.307-15.307,15.307c-8.44,0-15.307-6.867-15.307-15.307
                                        S452.36,241.748,460.8,241.748z M445.493,284.16c4.525,2.565,9.746,4.038,15.307,4.038c5.561,0,10.783-1.473,15.307-4.038v53.654
                                        h-30.614V284.16z M423.911,241.748h9.785c-2.565,4.525-4.038,9.746-4.038,15.307v14.995L423.911,241.748z M476.107,464.823
                                        c-0.029,0.178-0.052,0.36-0.068,0.545c-0.715,7.928-7.266,13.906-15.24,13.906c-7.973,0-14.525-5.978-15.24-13.906
                                        c-0.017-0.183-0.039-0.363-0.068-0.542v-20.389h30.614V464.823z M495.109,420.685c0,4.366-3.551,7.917-7.918,7.917h-54.895
                                        c-4.188,0-7.618-3.27-7.891-7.39h17.392c4.373,0,7.917-3.545,7.917-7.918s-3.545-7.918-7.917-7.918h-17.419v-5.278h17.419
                                        c4.373,0,7.917-3.545,7.917-7.918s-3.545-7.918-7.917-7.918h-17.419v-5.278h17.419c4.373,0,7.917-3.545,7.917-7.918
                                        s-3.545-7.918-7.917-7.918h-17.419v-1.583c0-4.366,3.551-7.918,7.918-7.918h54.895c4.366,0,7.918,3.551,7.918,7.918V420.685z"/>
                                        <path d="M198.994,432.825H76.008v-72.313c0-4.373-3.545-7.918-7.918-7.918c-4.373,0-7.917,3.545-7.917,7.918v80.231
                                        c0,4.373,3.545,7.917,7.917,7.917h130.903c4.373,0,7.918-3.545,7.918-7.917C206.911,436.37,203.366,432.825,198.994,432.825z"/>
                                        <path d="M195.874,365.208c-3.236-2.941-8.244-2.703-11.185,0.533l-17.079,18.787l-15.748-7.874
                                        c-2.909-1.456-6.417-0.982-8.837,1.196l-15,13.5l-15.848-19.018c-3.704-4.444-11.02-3.397-13.336,1.895l-14.779,33.781
                                        c-2.245,5.135,1.625,11.095,7.249,11.095c3.05,0,5.957-1.773,7.258-4.746l9.645-22.044l12.912,15.494
                                        c2.831,3.398,8.087,3.778,11.379,0.816l17.103-15.393l16.287,8.143c3.172,1.586,7.014,0.869,9.4-1.756l21.113-23.225
                                        C199.349,373.157,199.11,368.15,195.874,365.208z"/>

                </svg>
                <h1 class="text-xl font-bold my-7">
                Finishing Services
                </h1>
                <p class="w-full">
                    Boundless Construction Company offers finishing services in Jazan and the southern region, providing the best offers in finishing.
                </p>
            </div>
            <div data-aos="zoom-in" data-aos-duration="500" class="bg-white image-container image-six rounded w-full h-auto shadow-2xl p-8 hover:text-white duration-300"
                >
                <svg class="icon h-24 w-24 m-auto" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512" xml:space="preserve">
                                    <path d="M335.004,211.97c-2.745-3.403-7.73-3.938-11.133-1.193c-5.126,4.133-10.783,4.579-12.976,4.579
                                        c-6.584,0-11.269-3.204-12.977-4.581c-3.405-2.744-8.388-2.207-11.133,1.195c-2.744,3.405-2.208,8.389,1.195,11.133
                                        c3.747,3.02,11.706,8.088,22.915,8.088c4.813,0,14.188-1.051,22.917-8.089C337.215,220.358,337.749,215.374,335.004,211.97z"/>
                                        <path d="M359.456,175.241h-5.278c-4.373,0-7.917,3.545-7.917,7.917c0,4.373,3.545,7.918,7.917,7.918h5.278
                                        c4.373,0,7.918-3.545,7.918-7.918C367.373,178.786,363.828,175.241,359.456,175.241z"/>
                                        <path d="M267.612,175.241h-5.278c-4.373,0-7.918,3.545-7.918,7.917c0,4.373,3.545,7.918,7.918,7.918h5.278
                                        c4.373,0,7.918-3.545,7.918-7.918C275.53,178.786,271.985,175.241,267.612,175.241z"/>
                                        <path d="M491.942,338.293v-81.237c0-17.172-13.971-31.142-31.142-31.142h-46.449c-4.887,0-8.689,4.588-7.779,9.392l6.227,32.834
                                        h-67.015c11.642-3.62,22.7-9.077,32.671-16.092c9.867-6.943,18.661-15.395,25.99-24.979c4.719-6.173,8.834-12.819,12.25-19.802
                                        c3.856,1.837,8.066,2.812,12.436,2.812c16.008,0,29.031-13.023,29.031-29.031c0-16.008-13.023-29.032-29.031-29.032
                                        c-0.197,0-0.395,0.011-0.592,0.015c-0.011-0.357-0.024-0.714-0.038-1.07h13.298c4.373,0,7.917-3.545,7.917-7.918V121.93
                                        c0-4.373-3.545-7.918-7.917-7.918h-15.014c-8.008-45.276-42.375-82.201-86.858-93.507V7.918c0-4.373-3.545-7.918-7.918-7.918
                                        h-42.227c-4.373,0-7.917,3.545-7.917,7.918v12.588c-44.484,11.306-78.85,48.232-86.858,93.507h-15.014
                                        c-4.373,0-7.918,3.545-7.918,7.918v21.113c0,4.373,3.545,7.918,7.918,7.918h13.298c-0.014,0.357-0.027,0.714-0.038,1.07
                                        c-0.197-0.004-0.395-0.015-0.592-0.015c-16.008,0-29.031,13.023-29.031,29.031s13.023,29.031,29.031,29.031
                                        c4.369,0,8.579-0.975,12.436-2.812c5.741,11.734,13.438,22.478,22.681,31.706c8.557,8.541,18.418,15.768,29.154,21.33
                                        c6.111,3.167,12.502,5.795,19.075,7.838h-60.121c-16.44,0-31.107,10.244-36.934,25.336H45.922
                                        c-13.098,0-23.753,10.655-23.753,23.753v85.509c0,4.373,3.545,7.918,7.918,7.918c4.373,0,7.918-3.545,7.918-7.918v-64.22h199.522
                                        v123.865H38.004v-17.419c0-4.373-3.545-7.918-7.918-7.918c-4.373,0-7.918,3.545-7.918,7.918v17.419H8.973
                                        c-4.373,0-7.918,3.545-7.918,7.917v17.946C1.056,501.345,11.711,512,24.808,512h225.913c0.235,0,0.469-0.011,0.702-0.018
                                        c0.118,0.005,0.234,0.018,0.354,0.018h105.567c4.373,0,7.917-3.545,7.917-7.917c0-4.373-3.545-7.918-7.917-7.918h-84.232
                                        c0.879-2.478,1.362-5.142,1.362-7.918v-17.946c0-4.373-3.545-7.917-7.918-7.917h-13.196V317.229
                                        c0-13.098-10.655-23.753-23.753-23.753h-32.703c4.397-5.831,11.365-9.501,18.979-9.501h57.223l10.783,41.334l-10.445,125.332
                                        c-0.221,2.653,0.906,5.239,2.998,6.884l29.559,23.225c2.838,2.23,6.945,2.23,9.784,0l29.559-23.225
                                        c2.092-1.645,3.22-4.231,2.998-6.884l-10.445-125.332l10.785-41.334h67.12l10.364,54.646
                                        c-10.134,2.71-17.621,11.968-17.621,22.946v59.118c0,12.205,9.254,22.285,21.113,23.603v51.878h-30.087
                                        c-4.373,0-7.918,3.545-7.918,7.918c0,4.373,3.545,7.917,7.918,7.917h38.004c4.373,0,7.918-3.545,7.918-7.917v-12.945
                                        c4.521,2.534,9.743,3.971,15.307,3.971c15.74,0,28.759-11.46,30.849-26.888c0.191-0.682,0.293-1.401,0.293-2.143v-22.119
                                        c10.829-2.206,19.002-11.803,19.002-23.274v-59.118C510.944,350.096,502.771,340.499,491.942,338.293z M258.639,478.219v10.029
                                        c0,4.366-3.551,7.918-7.917,7.918H24.808c-4.366,0-7.917-3.551-7.917-7.918v-10.029H258.639z M229.608,309.311
                                        c0.546,0,1.079,0.056,1.594,0.162c3.605,0.74,6.323,3.937,6.323,7.756v5.455H38.004v-5.455c0-3.819,2.719-7.017,6.323-7.756
                                        c0.515-0.106,1.048-0.162,1.594-0.162H229.608z M407.489,188.095v-31.912c1.751-0.68,3.5-1.373,5.24-2.09
                                        c0.008,0.539,0.038,1.08,0.038,1.618C412.767,167.027,410.91,177.917,407.489,188.095z M429.129,167.852
                                        c7.278,0,13.197,5.919,13.197,13.196s-5.919,13.196-13.196,13.196c-2.29,0-4.484-0.584-6.442-1.685
                                        c2.605-7.885,4.403-16.133,5.284-24.65C428.356,167.876,428.741,167.852,429.129,167.852z M339.926,36.925
                                        c35.706,10.623,63.209,40.491,70.745,77.087h-70.745V36.925z M297.699,15.835h26.392v98.177h-26.392V15.835z M192.66,194.243
                                        c-7.277,0-13.196-5.919-13.196-13.196s5.919-13.196,13.196-13.196c0.387,0,0.773,0.024,1.157,0.057
                                        c0.88,8.517,2.678,16.765,5.284,24.65C197.144,193.66,194.95,194.243,192.66,194.243z M214.301,188.095
                                        c-3.421-10.178-5.278-21.068-5.278-32.384c0-0.538,0.031-1.079,0.038-1.618c1.741,0.718,3.488,1.411,5.24,2.091V188.095z
                                        M187.909,135.126v-5.278h42.755c4.373,0,7.918-3.545,7.918-7.917c0-4.373-3.545-7.918-7.918-7.918h-19.545
                                        c7.535-36.596,35.039-66.464,70.745-77.087v77.087h-17.419c-4.373,0-7.918,3.545-7.918,7.918c0,4.373,3.545,7.917,7.918,7.917
                                        H433.88v5.278H187.909z M371.653,150.961c-19.736,4.899-40.05,7.39-60.758,7.39c-20.707,0-41.022-2.49-60.758-7.39H371.653z
                                        M310.895,464.455l-21.314-16.747l9.597-115.172h23.434l9.597,115.172L310.895,464.455z M323.78,316.701h-25.77l-8.537-32.726
                                        h42.844L323.78,316.701z M310.895,257.583c-32.852,0-62.118-15.637-80.759-39.853V161.78c25.993,8.24,53.052,12.405,80.759,12.405
                                        c27.707,0,54.766-4.166,80.759-12.405v55.951C373.013,241.947,343.747,257.583,310.895,257.583z M460.8,241.748
                                        c8.44,0,15.307,6.867,15.307,15.307s-6.867,15.307-15.307,15.307c-8.44,0-15.307-6.867-15.307-15.307
                                        S452.36,241.748,460.8,241.748z M445.493,284.16c4.525,2.565,9.746,4.038,15.307,4.038c5.561,0,10.783-1.473,15.307-4.038v53.654
                                        h-30.614V284.16z M423.911,241.748h9.785c-2.565,4.525-4.038,9.746-4.038,15.307v14.995L423.911,241.748z M476.107,464.823
                                        c-0.029,0.178-0.052,0.36-0.068,0.545c-0.715,7.928-7.266,13.906-15.24,13.906c-7.973,0-14.525-5.978-15.24-13.906
                                        c-0.017-0.183-0.039-0.363-0.068-0.542v-20.389h30.614V464.823z M495.109,420.685c0,4.366-3.551,7.917-7.918,7.917h-54.895
                                        c-4.188,0-7.618-3.27-7.891-7.39h17.392c4.373,0,7.917-3.545,7.917-7.918s-3.545-7.918-7.917-7.918h-17.419v-5.278h17.419
                                        c4.373,0,7.917-3.545,7.917-7.918s-3.545-7.918-7.917-7.918h-17.419v-5.278h17.419c4.373,0,7.917-3.545,7.917-7.918
                                        s-3.545-7.918-7.917-7.918h-17.419v-1.583c0-4.366,3.551-7.918,7.918-7.918h54.895c4.366,0,7.918,3.551,7.918,7.918V420.685z"/>
                                        <path d="M198.994,432.825H76.008v-72.313c0-4.373-3.545-7.918-7.918-7.918c-4.373,0-7.917,3.545-7.917,7.918v80.231
                                        c0,4.373,3.545,7.917,7.917,7.917h130.903c4.373,0,7.918-3.545,7.918-7.917C206.911,436.37,203.366,432.825,198.994,432.825z"/>
                                        <path d="M195.874,365.208c-3.236-2.941-8.244-2.703-11.185,0.533l-17.079,18.787l-15.748-7.874
                                        c-2.909-1.456-6.417-0.982-8.837,1.196l-15,13.5l-15.848-19.018c-3.704-4.444-11.02-3.397-13.336,1.895l-14.779,33.781
                                        c-2.245,5.135,1.625,11.095,7.249,11.095c3.05,0,5.957-1.773,7.258-4.746l9.645-22.044l12.912,15.494
                                        c2.831,3.398,8.087,3.778,11.379,0.816l17.103-15.393l16.287,8.143c3.172,1.586,7.014,0.869,9.4-1.756l21.113-23.225
                                        C199.349,373.157,199.11,368.15,195.874,365.208z"/>

                </svg>
                <h1 class="text-xl font-bold my-7">
                    Ceramic, Stone, and Marble Breaking Works
                </h1>
                <p class="w-full">
                    Boundless Construction Company offers you ceramic, stone, and marble breaking works with the strongest special offers for its clients with global quality.
                </p>

            </div>
            <div data-aos="zoom-in" data-aos-duration="500" class="bg-white image-container image-three rounded w-full h-auto shadow-2xl p-8 hover:text-white duration-300"
                >
                <svg class="icon h-24 w-24 m-auto" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512" xml:space="preserve">
                                    <path d="M335.004,211.97c-2.745-3.403-7.73-3.938-11.133-1.193c-5.126,4.133-10.783,4.579-12.976,4.579
                                        c-6.584,0-11.269-3.204-12.977-4.581c-3.405-2.744-8.388-2.207-11.133,1.195c-2.744,3.405-2.208,8.389,1.195,11.133
                                        c3.747,3.02,11.706,8.088,22.915,8.088c4.813,0,14.188-1.051,22.917-8.089C337.215,220.358,337.749,215.374,335.004,211.97z"/>
                                        <path d="M359.456,175.241h-5.278c-4.373,0-7.917,3.545-7.917,7.917c0,4.373,3.545,7.918,7.917,7.918h5.278
                                        c4.373,0,7.918-3.545,7.918-7.918C367.373,178.786,363.828,175.241,359.456,175.241z"/>
                                        <path d="M267.612,175.241h-5.278c-4.373,0-7.918,3.545-7.918,7.917c0,4.373,3.545,7.918,7.918,7.918h5.278
                                        c4.373,0,7.918-3.545,7.918-7.918C275.53,178.786,271.985,175.241,267.612,175.241z"/>
                                        <path d="M491.942,338.293v-81.237c0-17.172-13.971-31.142-31.142-31.142h-46.449c-4.887,0-8.689,4.588-7.779,9.392l6.227,32.834
                                        h-67.015c11.642-3.62,22.7-9.077,32.671-16.092c9.867-6.943,18.661-15.395,25.99-24.979c4.719-6.173,8.834-12.819,12.25-19.802
                                        c3.856,1.837,8.066,2.812,12.436,2.812c16.008,0,29.031-13.023,29.031-29.031c0-16.008-13.023-29.032-29.031-29.032
                                        c-0.197,0-0.395,0.011-0.592,0.015c-0.011-0.357-0.024-0.714-0.038-1.07h13.298c4.373,0,7.917-3.545,7.917-7.918V121.93
                                        c0-4.373-3.545-7.918-7.917-7.918h-15.014c-8.008-45.276-42.375-82.201-86.858-93.507V7.918c0-4.373-3.545-7.918-7.918-7.918
                                        h-42.227c-4.373,0-7.917,3.545-7.917,7.918v12.588c-44.484,11.306-78.85,48.232-86.858,93.507h-15.014
                                        c-4.373,0-7.918,3.545-7.918,7.918v21.113c0,4.373,3.545,7.918,7.918,7.918h13.298c-0.014,0.357-0.027,0.714-0.038,1.07
                                        c-0.197-0.004-0.395-0.015-0.592-0.015c-16.008,0-29.031,13.023-29.031,29.031s13.023,29.031,29.031,29.031
                                        c4.369,0,8.579-0.975,12.436-2.812c5.741,11.734,13.438,22.478,22.681,31.706c8.557,8.541,18.418,15.768,29.154,21.33
                                        c6.111,3.167,12.502,5.795,19.075,7.838h-60.121c-16.44,0-31.107,10.244-36.934,25.336H45.922
                                        c-13.098,0-23.753,10.655-23.753,23.753v85.509c0,4.373,3.545,7.918,7.918,7.918c4.373,0,7.918-3.545,7.918-7.918v-64.22h199.522
                                        v123.865H38.004v-17.419c0-4.373-3.545-7.918-7.918-7.918c-4.373,0-7.918,3.545-7.918,7.918v17.419H8.973
                                        c-4.373,0-7.918,3.545-7.918,7.917v17.946C1.056,501.345,11.711,512,24.808,512h225.913c0.235,0,0.469-0.011,0.702-0.018
                                        c0.118,0.005,0.234,0.018,0.354,0.018h105.567c4.373,0,7.917-3.545,7.917-7.917c0-4.373-3.545-7.918-7.917-7.918h-84.232
                                        c0.879-2.478,1.362-5.142,1.362-7.918v-17.946c0-4.373-3.545-7.917-7.918-7.917h-13.196V317.229
                                        c0-13.098-10.655-23.753-23.753-23.753h-32.703c4.397-5.831,11.365-9.501,18.979-9.501h57.223l10.783,41.334l-10.445,125.332
                                        c-0.221,2.653,0.906,5.239,2.998,6.884l29.559,23.225c2.838,2.23,6.945,2.23,9.784,0l29.559-23.225
                                        c2.092-1.645,3.22-4.231,2.998-6.884l-10.445-125.332l10.785-41.334h67.12l10.364,54.646
                                        c-10.134,2.71-17.621,11.968-17.621,22.946v59.118c0,12.205,9.254,22.285,21.113,23.603v51.878h-30.087
                                        c-4.373,0-7.918,3.545-7.918,7.918c0,4.373,3.545,7.917,7.918,7.917h38.004c4.373,0,7.918-3.545,7.918-7.917v-12.945
                                        c4.521,2.534,9.743,3.971,15.307,3.971c15.74,0,28.759-11.46,30.849-26.888c0.191-0.682,0.293-1.401,0.293-2.143v-22.119
                                        c10.829-2.206,19.002-11.803,19.002-23.274v-59.118C510.944,350.096,502.771,340.499,491.942,338.293z M258.639,478.219v10.029
                                        c0,4.366-3.551,7.918-7.917,7.918H24.808c-4.366,0-7.917-3.551-7.917-7.918v-10.029H258.639z M229.608,309.311
                                        c0.546,0,1.079,0.056,1.594,0.162c3.605,0.74,6.323,3.937,6.323,7.756v5.455H38.004v-5.455c0-3.819,2.719-7.017,6.323-7.756
                                        c0.515-0.106,1.048-0.162,1.594-0.162H229.608z M407.489,188.095v-31.912c1.751-0.68,3.5-1.373,5.24-2.09
                                        c0.008,0.539,0.038,1.08,0.038,1.618C412.767,167.027,410.91,177.917,407.489,188.095z M429.129,167.852
                                        c7.278,0,13.197,5.919,13.197,13.196s-5.919,13.196-13.196,13.196c-2.29,0-4.484-0.584-6.442-1.685
                                        c2.605-7.885,4.403-16.133,5.284-24.65C428.356,167.876,428.741,167.852,429.129,167.852z M339.926,36.925
                                        c35.706,10.623,63.209,40.491,70.745,77.087h-70.745V36.925z M297.699,15.835h26.392v98.177h-26.392V15.835z M192.66,194.243
                                        c-7.277,0-13.196-5.919-13.196-13.196s5.919-13.196,13.196-13.196c0.387,0,0.773,0.024,1.157,0.057
                                        c0.88,8.517,2.678,16.765,5.284,24.65C197.144,193.66,194.95,194.243,192.66,194.243z M214.301,188.095
                                        c-3.421-10.178-5.278-21.068-5.278-32.384c0-0.538,0.031-1.079,0.038-1.618c1.741,0.718,3.488,1.411,5.24,2.091V188.095z
                                        M187.909,135.126v-5.278h42.755c4.373,0,7.918-3.545,7.918-7.917c0-4.373-3.545-7.918-7.918-7.918h-19.545
                                        c7.535-36.596,35.039-66.464,70.745-77.087v77.087h-17.419c-4.373,0-7.918,3.545-7.918,7.918c0,4.373,3.545,7.917,7.918,7.917
                                        H433.88v5.278H187.909z M371.653,150.961c-19.736,4.899-40.05,7.39-60.758,7.39c-20.707,0-41.022-2.49-60.758-7.39H371.653z
                                        M310.895,464.455l-21.314-16.747l9.597-115.172h23.434l9.597,115.172L310.895,464.455z M323.78,316.701h-25.77l-8.537-32.726
                                        h42.844L323.78,316.701z M310.895,257.583c-32.852,0-62.118-15.637-80.759-39.853V161.78c25.993,8.24,53.052,12.405,80.759,12.405
                                        c27.707,0,54.766-4.166,80.759-12.405v55.951C373.013,241.947,343.747,257.583,310.895,257.583z M460.8,241.748
                                        c8.44,0,15.307,6.867,15.307,15.307s-6.867,15.307-15.307,15.307c-8.44,0-15.307-6.867-15.307-15.307
                                        S452.36,241.748,460.8,241.748z M445.493,284.16c4.525,2.565,9.746,4.038,15.307,4.038c5.561,0,10.783-1.473,15.307-4.038v53.654
                                        h-30.614V284.16z M423.911,241.748h9.785c-2.565,4.525-4.038,9.746-4.038,15.307v14.995L423.911,241.748z M476.107,464.823
                                        c-0.029,0.178-0.052,0.36-0.068,0.545c-0.715,7.928-7.266,13.906-15.24,13.906c-7.973,0-14.525-5.978-15.24-13.906
                                        c-0.017-0.183-0.039-0.363-0.068-0.542v-20.389h30.614V464.823z M495.109,420.685c0,4.366-3.551,7.917-7.918,7.917h-54.895
                                        c-4.188,0-7.618-3.27-7.891-7.39h17.392c4.373,0,7.917-3.545,7.917-7.918s-3.545-7.918-7.917-7.918h-17.419v-5.278h17.419
                                        c4.373,0,7.917-3.545,7.917-7.918s-3.545-7.918-7.917-7.918h-17.419v-5.278h17.419c4.373,0,7.917-3.545,7.917-7.918
                                        s-3.545-7.918-7.917-7.918h-17.419v-1.583c0-4.366,3.551-7.918,7.918-7.918h54.895c4.366,0,7.918,3.551,7.918,7.918V420.685z"/>
                                        <path d="M198.994,432.825H76.008v-72.313c0-4.373-3.545-7.918-7.918-7.918c-4.373,0-7.917,3.545-7.917,7.918v80.231
                                        c0,4.373,3.545,7.917,7.917,7.917h130.903c4.373,0,7.918-3.545,7.918-7.917C206.911,436.37,203.366,432.825,198.994,432.825z"/>
                                        <path d="M195.874,365.208c-3.236-2.941-8.244-2.703-11.185,0.533l-17.079,18.787l-15.748-7.874
                                        c-2.909-1.456-6.417-0.982-8.837,1.196l-15,13.5l-15.848-19.018c-3.704-4.444-11.02-3.397-13.336,1.895l-14.779,33.781
                                        c-2.245,5.135,1.625,11.095,7.249,11.095c3.05,0,5.957-1.773,7.258-4.746l9.645-22.044l12.912,15.494
                                        c2.831,3.398,8.087,3.778,11.379,0.816l17.103-15.393l16.287,8.143c3.172,1.586,7.014,0.869,9.4-1.756l21.113-23.225
                                        C199.349,373.157,199.11,368.15,195.874,365.208z"/>

                </svg>
                <h1 class="text-xl font-bold my-7">
                    Interior and Exterior Design
                </h1>
                <p class="w-full">
                    The exterior design of villas and homes reflects the personality and taste of the interior design, as it is a mirror of the interior design. Therefore, you can trust us for all design works.
                </p>

            </div>
            <div data-aos="zoom-in" data-aos-duration="500" class="bg-white image-container image-four rounded w-full h-auto shadow-2xl p-8 hover:text-white duration-300"
                >
                <svg class="icon h-24 w-24 m-auto" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512" xml:space="preserve">
                                    <path d="M335.004,211.97c-2.745-3.403-7.73-3.938-11.133-1.193c-5.126,4.133-10.783,4.579-12.976,4.579
                                        c-6.584,0-11.269-3.204-12.977-4.581c-3.405-2.744-8.388-2.207-11.133,1.195c-2.744,3.405-2.208,8.389,1.195,11.133
                                        c3.747,3.02,11.706,8.088,22.915,8.088c4.813,0,14.188-1.051,22.917-8.089C337.215,220.358,337.749,215.374,335.004,211.97z"/>
                                        <path d="M359.456,175.241h-5.278c-4.373,0-7.917,3.545-7.917,7.917c0,4.373,3.545,7.918,7.917,7.918h5.278
                                        c4.373,0,7.918-3.545,7.918-7.918C367.373,178.786,363.828,175.241,359.456,175.241z"/>
                                        <path d="M267.612,175.241h-5.278c-4.373,0-7.918,3.545-7.918,7.917c0,4.373,3.545,7.918,7.918,7.918h5.278
                                        c4.373,0,7.918-3.545,7.918-7.918C275.53,178.786,271.985,175.241,267.612,175.241z"/>
                                        <path d="M491.942,338.293v-81.237c0-17.172-13.971-31.142-31.142-31.142h-46.449c-4.887,0-8.689,4.588-7.779,9.392l6.227,32.834
                                        h-67.015c11.642-3.62,22.7-9.077,32.671-16.092c9.867-6.943,18.661-15.395,25.99-24.979c4.719-6.173,8.834-12.819,12.25-19.802
                                        c3.856,1.837,8.066,2.812,12.436,2.812c16.008,0,29.031-13.023,29.031-29.031c0-16.008-13.023-29.032-29.031-29.032
                                        c-0.197,0-0.395,0.011-0.592,0.015c-0.011-0.357-0.024-0.714-0.038-1.07h13.298c4.373,0,7.917-3.545,7.917-7.918V121.93
                                        c0-4.373-3.545-7.918-7.917-7.918h-15.014c-8.008-45.276-42.375-82.201-86.858-93.507V7.918c0-4.373-3.545-7.918-7.918-7.918
                                        h-42.227c-4.373,0-7.917,3.545-7.917,7.918v12.588c-44.484,11.306-78.85,48.232-86.858,93.507h-15.014
                                        c-4.373,0-7.918,3.545-7.918,7.918v21.113c0,4.373,3.545,7.918,7.918,7.918h13.298c-0.014,0.357-0.027,0.714-0.038,1.07
                                        c-0.197-0.004-0.395-0.015-0.592-0.015c-16.008,0-29.031,13.023-29.031,29.031s13.023,29.031,29.031,29.031
                                        c4.369,0,8.579-0.975,12.436-2.812c5.741,11.734,13.438,22.478,22.681,31.706c8.557,8.541,18.418,15.768,29.154,21.33
                                        c6.111,3.167,12.502,5.795,19.075,7.838h-60.121c-16.44,0-31.107,10.244-36.934,25.336H45.922
                                        c-13.098,0-23.753,10.655-23.753,23.753v85.509c0,4.373,3.545,7.918,7.918,7.918c4.373,0,7.918-3.545,7.918-7.918v-64.22h199.522
                                        v123.865H38.004v-17.419c0-4.373-3.545-7.918-7.918-7.918c-4.373,0-7.918,3.545-7.918,7.918v17.419H8.973
                                        c-4.373,0-7.918,3.545-7.918,7.917v17.946C1.056,501.345,11.711,512,24.808,512h225.913c0.235,0,0.469-0.011,0.702-0.018
                                        c0.118,0.005,0.234,0.018,0.354,0.018h105.567c4.373,0,7.917-3.545,7.917-7.917c0-4.373-3.545-7.918-7.917-7.918h-84.232
                                        c0.879-2.478,1.362-5.142,1.362-7.918v-17.946c0-4.373-3.545-7.917-7.918-7.917h-13.196V317.229
                                        c0-13.098-10.655-23.753-23.753-23.753h-32.703c4.397-5.831,11.365-9.501,18.979-9.501h57.223l10.783,41.334l-10.445,125.332
                                        c-0.221,2.653,0.906,5.239,2.998,6.884l29.559,23.225c2.838,2.23,6.945,2.23,9.784,0l29.559-23.225
                                        c2.092-1.645,3.22-4.231,2.998-6.884l-10.445-125.332l10.785-41.334h67.12l10.364,54.646
                                        c-10.134,2.71-17.621,11.968-17.621,22.946v59.118c0,12.205,9.254,22.285,21.113,23.603v51.878h-30.087
                                        c-4.373,0-7.918,3.545-7.918,7.918c0,4.373,3.545,7.917,7.918,7.917h38.004c4.373,0,7.918-3.545,7.918-7.917v-12.945
                                        c4.521,2.534,9.743,3.971,15.307,3.971c15.74,0,28.759-11.46,30.849-26.888c0.191-0.682,0.293-1.401,0.293-2.143v-22.119
                                        c10.829-2.206,19.002-11.803,19.002-23.274v-59.118C510.944,350.096,502.771,340.499,491.942,338.293z M258.639,478.219v10.029
                                        c0,4.366-3.551,7.918-7.917,7.918H24.808c-4.366,0-7.917-3.551-7.917-7.918v-10.029H258.639z M229.608,309.311
                                        c0.546,0,1.079,0.056,1.594,0.162c3.605,0.74,6.323,3.937,6.323,7.756v5.455H38.004v-5.455c0-3.819,2.719-7.017,6.323-7.756
                                        c0.515-0.106,1.048-0.162,1.594-0.162H229.608z M407.489,188.095v-31.912c1.751-0.68,3.5-1.373,5.24-2.09
                                        c0.008,0.539,0.038,1.08,0.038,1.618C412.767,167.027,410.91,177.917,407.489,188.095z M429.129,167.852
                                        c7.278,0,13.197,5.919,13.197,13.196s-5.919,13.196-13.196,13.196c-2.29,0-4.484-0.584-6.442-1.685
                                        c2.605-7.885,4.403-16.133,5.284-24.65C428.356,167.876,428.741,167.852,429.129,167.852z M339.926,36.925
                                        c35.706,10.623,63.209,40.491,70.745,77.087h-70.745V36.925z M297.699,15.835h26.392v98.177h-26.392V15.835z M192.66,194.243
                                        c-7.277,0-13.196-5.919-13.196-13.196s5.919-13.196,13.196-13.196c0.387,0,0.773,0.024,1.157,0.057
                                        c0.88,8.517,2.678,16.765,5.284,24.65C197.144,193.66,194.95,194.243,192.66,194.243z M214.301,188.095
                                        c-3.421-10.178-5.278-21.068-5.278-32.384c0-0.538,0.031-1.079,0.038-1.618c1.741,0.718,3.488,1.411,5.24,2.091V188.095z
                                        M187.909,135.126v-5.278h42.755c4.373,0,7.918-3.545,7.918-7.917c0-4.373-3.545-7.918-7.918-7.918h-19.545
                                        c7.535-36.596,35.039-66.464,70.745-77.087v77.087h-17.419c-4.373,0-7.918,3.545-7.918,7.918c0,4.373,3.545,7.917,7.918,7.917
                                        H433.88v5.278H187.909z M371.653,150.961c-19.736,4.899-40.05,7.39-60.758,7.39c-20.707,0-41.022-2.49-60.758-7.39H371.653z
                                        M310.895,464.455l-21.314-16.747l9.597-115.172h23.434l9.597,115.172L310.895,464.455z M323.78,316.701h-25.77l-8.537-32.726
                                        h42.844L323.78,316.701z M310.895,257.583c-32.852,0-62.118-15.637-80.759-39.853V161.78c25.993,8.24,53.052,12.405,80.759,12.405
                                        c27.707,0,54.766-4.166,80.759-12.405v55.951C373.013,241.947,343.747,257.583,310.895,257.583z M460.8,241.748
                                        c8.44,0,15.307,6.867,15.307,15.307s-6.867,15.307-15.307,15.307c-8.44,0-15.307-6.867-15.307-15.307
                                        S452.36,241.748,460.8,241.748z M445.493,284.16c4.525,2.565,9.746,4.038,15.307,4.038c5.561,0,10.783-1.473,15.307-4.038v53.654
                                        h-30.614V284.16z M423.911,241.748h9.785c-2.565,4.525-4.038,9.746-4.038,15.307v14.995L423.911,241.748z M476.107,464.823
                                        c-0.029,0.178-0.052,0.36-0.068,0.545c-0.715,7.928-7.266,13.906-15.24,13.906c-7.973,0-14.525-5.978-15.24-13.906
                                        c-0.017-0.183-0.039-0.363-0.068-0.542v-20.389h30.614V464.823z M495.109,420.685c0,4.366-3.551,7.917-7.918,7.917h-54.895
                                        c-4.188,0-7.618-3.27-7.891-7.39h17.392c4.373,0,7.917-3.545,7.917-7.918s-3.545-7.918-7.917-7.918h-17.419v-5.278h17.419
                                        c4.373,0,7.917-3.545,7.917-7.918s-3.545-7.918-7.917-7.918h-17.419v-5.278h17.419c4.373,0,7.917-3.545,7.917-7.918
                                        s-3.545-7.918-7.917-7.918h-17.419v-1.583c0-4.366,3.551-7.918,7.918-7.918h54.895c4.366,0,7.918,3.551,7.918,7.918V420.685z"/>
                                        <path d="M198.994,432.825H76.008v-72.313c0-4.373-3.545-7.918-7.918-7.918c-4.373,0-7.917,3.545-7.917,7.918v80.231
                                        c0,4.373,3.545,7.917,7.917,7.917h130.903c4.373,0,7.918-3.545,7.918-7.917C206.911,436.37,203.366,432.825,198.994,432.825z"/>
                                        <path d="M195.874,365.208c-3.236-2.941-8.244-2.703-11.185,0.533l-17.079,18.787l-15.748-7.874
                                        c-2.909-1.456-6.417-0.982-8.837,1.196l-15,13.5l-15.848-19.018c-3.704-4.444-11.02-3.397-13.336,1.895l-14.779,33.781
                                        c-2.245,5.135,1.625,11.095,7.249,11.095c3.05,0,5.957-1.773,7.258-4.746l9.645-22.044l12.912,15.494
                                        c2.831,3.398,8.087,3.778,11.379,0.816l17.103-15.393l16.287,8.143c3.172,1.586,7.014,0.869,9.4-1.756l21.113-23.225
                                        C199.349,373.157,199.11,368.15,195.874,365.208z"/>

                </svg>
                <h1 class="text-xl font-bold my-7">
                    Plumbing and Electrical Works
                    </h1>
                    <p class="w-full">
                        Embracing Boundless Construction Company in Jazan and the southern region offers the best services in plumbing and electrical works with world-class execution quality and competitive prices.
                    </p>


                </div>
            <div data-aos="zoom-in" data-aos-duration="500" class="bg-white image-container image-five rounded w-full h-auto shadow-2xl p-8 hover:text-white duration-300"
                >
                <svg class="icon h-24 w-24 m-auto" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512" xml:space="preserve">
                                    <path d="M335.004,211.97c-2.745-3.403-7.73-3.938-11.133-1.193c-5.126,4.133-10.783,4.579-12.976,4.579
                                        c-6.584,0-11.269-3.204-12.977-4.581c-3.405-2.744-8.388-2.207-11.133,1.195c-2.744,3.405-2.208,8.389,1.195,11.133
                                        c3.747,3.02,11.706,8.088,22.915,8.088c4.813,0,14.188-1.051,22.917-8.089C337.215,220.358,337.749,215.374,335.004,211.97z"/>
                                        <path d="M359.456,175.241h-5.278c-4.373,0-7.917,3.545-7.917,7.917c0,4.373,3.545,7.918,7.917,7.918h5.278
                                        c4.373,0,7.918-3.545,7.918-7.918C367.373,178.786,363.828,175.241,359.456,175.241z"/>
                                        <path d="M267.612,175.241h-5.278c-4.373,0-7.918,3.545-7.918,7.917c0,4.373,3.545,7.918,7.918,7.918h5.278
                                        c4.373,0,7.918-3.545,7.918-7.918C275.53,178.786,271.985,175.241,267.612,175.241z"/>
                                        <path d="M491.942,338.293v-81.237c0-17.172-13.971-31.142-31.142-31.142h-46.449c-4.887,0-8.689,4.588-7.779,9.392l6.227,32.834
                                        h-67.015c11.642-3.62,22.7-9.077,32.671-16.092c9.867-6.943,18.661-15.395,25.99-24.979c4.719-6.173,8.834-12.819,12.25-19.802
                                        c3.856,1.837,8.066,2.812,12.436,2.812c16.008,0,29.031-13.023,29.031-29.031c0-16.008-13.023-29.032-29.031-29.032
                                        c-0.197,0-0.395,0.011-0.592,0.015c-0.011-0.357-0.024-0.714-0.038-1.07h13.298c4.373,0,7.917-3.545,7.917-7.918V121.93
                                        c0-4.373-3.545-7.918-7.917-7.918h-15.014c-8.008-45.276-42.375-82.201-86.858-93.507V7.918c0-4.373-3.545-7.918-7.918-7.918
                                        h-42.227c-4.373,0-7.917,3.545-7.917,7.918v12.588c-44.484,11.306-78.85,48.232-86.858,93.507h-15.014
                                        c-4.373,0-7.918,3.545-7.918,7.918v21.113c0,4.373,3.545,7.918,7.918,7.918h13.298c-0.014,0.357-0.027,0.714-0.038,1.07
                                        c-0.197-0.004-0.395-0.015-0.592-0.015c-16.008,0-29.031,13.023-29.031,29.031s13.023,29.031,29.031,29.031
                                        c4.369,0,8.579-0.975,12.436-2.812c5.741,11.734,13.438,22.478,22.681,31.706c8.557,8.541,18.418,15.768,29.154,21.33
                                        c6.111,3.167,12.502,5.795,19.075,7.838h-60.121c-16.44,0-31.107,10.244-36.934,25.336H45.922
                                        c-13.098,0-23.753,10.655-23.753,23.753v85.509c0,4.373,3.545,7.918,7.918,7.918c4.373,0,7.918-3.545,7.918-7.918v-64.22h199.522
                                        v123.865H38.004v-17.419c0-4.373-3.545-7.918-7.918-7.918c-4.373,0-7.918,3.545-7.918,7.918v17.419H8.973
                                        c-4.373,0-7.918,3.545-7.918,7.917v17.946C1.056,501.345,11.711,512,24.808,512h225.913c0.235,0,0.469-0.011,0.702-0.018
                                        c0.118,0.005,0.234,0.018,0.354,0.018h105.567c4.373,0,7.917-3.545,7.917-7.917c0-4.373-3.545-7.918-7.917-7.918h-84.232
                                        c0.879-2.478,1.362-5.142,1.362-7.918v-17.946c0-4.373-3.545-7.917-7.918-7.917h-13.196V317.229
                                        c0-13.098-10.655-23.753-23.753-23.753h-32.703c4.397-5.831,11.365-9.501,18.979-9.501h57.223l10.783,41.334l-10.445,125.332
                                        c-0.221,2.653,0.906,5.239,2.998,6.884l29.559,23.225c2.838,2.23,6.945,2.23,9.784,0l29.559-23.225
                                        c2.092-1.645,3.22-4.231,2.998-6.884l-10.445-125.332l10.785-41.334h67.12l10.364,54.646
                                        c-10.134,2.71-17.621,11.968-17.621,22.946v59.118c0,12.205,9.254,22.285,21.113,23.603v51.878h-30.087
                                        c-4.373,0-7.918,3.545-7.918,7.918c0,4.373,3.545,7.917,7.918,7.917h38.004c4.373,0,7.918-3.545,7.918-7.917v-12.945
                                        c4.521,2.534,9.743,3.971,15.307,3.971c15.74,0,28.759-11.46,30.849-26.888c0.191-0.682,0.293-1.401,0.293-2.143v-22.119
                                        c10.829-2.206,19.002-11.803,19.002-23.274v-59.118C510.944,350.096,502.771,340.499,491.942,338.293z M258.639,478.219v10.029
                                        c0,4.366-3.551,7.918-7.917,7.918H24.808c-4.366,0-7.917-3.551-7.917-7.918v-10.029H258.639z M229.608,309.311
                                        c0.546,0,1.079,0.056,1.594,0.162c3.605,0.74,6.323,3.937,6.323,7.756v5.455H38.004v-5.455c0-3.819,2.719-7.017,6.323-7.756
                                        c0.515-0.106,1.048-0.162,1.594-0.162H229.608z M407.489,188.095v-31.912c1.751-0.68,3.5-1.373,5.24-2.09
                                        c0.008,0.539,0.038,1.08,0.038,1.618C412.767,167.027,410.91,177.917,407.489,188.095z M429.129,167.852
                                        c7.278,0,13.197,5.919,13.197,13.196s-5.919,13.196-13.196,13.196c-2.29,0-4.484-0.584-6.442-1.685
                                        c2.605-7.885,4.403-16.133,5.284-24.65C428.356,167.876,428.741,167.852,429.129,167.852z M339.926,36.925
                                        c35.706,10.623,63.209,40.491,70.745,77.087h-70.745V36.925z M297.699,15.835h26.392v98.177h-26.392V15.835z M192.66,194.243
                                        c-7.277,0-13.196-5.919-13.196-13.196s5.919-13.196,13.196-13.196c0.387,0,0.773,0.024,1.157,0.057
                                        c0.88,8.517,2.678,16.765,5.284,24.65C197.144,193.66,194.95,194.243,192.66,194.243z M214.301,188.095
                                        c-3.421-10.178-5.278-21.068-5.278-32.384c0-0.538,0.031-1.079,0.038-1.618c1.741,0.718,3.488,1.411,5.24,2.091V188.095z
                                        M187.909,135.126v-5.278h42.755c4.373,0,7.918-3.545,7.918-7.917c0-4.373-3.545-7.918-7.918-7.918h-19.545
                                        c7.535-36.596,35.039-66.464,70.745-77.087v77.087h-17.419c-4.373,0-7.918,3.545-7.918,7.918c0,4.373,3.545,7.917,7.918,7.917
                                        H433.88v5.278H187.909z M371.653,150.961c-19.736,4.899-40.05,7.39-60.758,7.39c-20.707,0-41.022-2.49-60.758-7.39H371.653z
                                        M310.895,464.455l-21.314-16.747l9.597-115.172h23.434l9.597,115.172L310.895,464.455z M323.78,316.701h-25.77l-8.537-32.726
                                        h42.844L323.78,316.701z M310.895,257.583c-32.852,0-62.118-15.637-80.759-39.853V161.78c25.993,8.24,53.052,12.405,80.759,12.405
                                        c27.707,0,54.766-4.166,80.759-12.405v55.951C373.013,241.947,343.747,257.583,310.895,257.583z M460.8,241.748
                                        c8.44,0,15.307,6.867,15.307,15.307s-6.867,15.307-15.307,15.307c-8.44,0-15.307-6.867-15.307-15.307
                                        S452.36,241.748,460.8,241.748z M445.493,284.16c4.525,2.565,9.746,4.038,15.307,4.038c5.561,0,10.783-1.473,15.307-4.038v53.654
                                        h-30.614V284.16z M423.911,241.748h9.785c-2.565,4.525-4.038,9.746-4.038,15.307v14.995L423.911,241.748z M476.107,464.823
                                        c-0.029,0.178-0.052,0.36-0.068,0.545c-0.715,7.928-7.266,13.906-15.24,13.906c-7.973,0-14.525-5.978-15.24-13.906
                                        c-0.017-0.183-0.039-0.363-0.068-0.542v-20.389h30.614V464.823z M495.109,420.685c0,4.366-3.551,7.917-7.918,7.917h-54.895
                                        c-4.188,0-7.618-3.27-7.891-7.39h17.392c4.373,0,7.917-3.545,7.917-7.918s-3.545-7.918-7.917-7.918h-17.419v-5.278h17.419
                                        c4.373,0,7.917-3.545,7.917-7.918s-3.545-7.918-7.917-7.918h-17.419v-5.278h17.419c4.373,0,7.917-3.545,7.917-7.918
                                        s-3.545-7.918-7.917-7.918h-17.419v-1.583c0-4.366,3.551-7.918,7.918-7.918h54.895c4.366,0,7.918,3.551,7.918,7.918V420.685z"/>
                                        <path d="M198.994,432.825H76.008v-72.313c0-4.373-3.545-7.918-7.918-7.918c-4.373,0-7.917,3.545-7.917,7.918v80.231
                                        c0,4.373,3.545,7.917,7.917,7.917h130.903c4.373,0,7.918-3.545,7.918-7.917C206.911,436.37,203.366,432.825,198.994,432.825z"/>
                                        <path d="M195.874,365.208c-3.236-2.941-8.244-2.703-11.185,0.533l-17.079,18.787l-15.748-7.874
                                        c-2.909-1.456-6.417-0.982-8.837,1.196l-15,13.5l-15.848-19.018c-3.704-4.444-11.02-3.397-13.336,1.895l-14.779,33.781
                                        c-2.245,5.135,1.625,11.095,7.249,11.095c3.05,0,5.957-1.773,7.258-4.746l9.645-22.044l12.912,15.494
                                        c2.831,3.398,8.087,3.778,11.379,0.816l17.103-15.393l16.287,8.143c3.172,1.586,7.014,0.869,9.4-1.756l21.113-23.225
                                        C199.349,373.157,199.11,368.15,195.874,365.208z"/>

                </svg>
                <h1 class="text-xl font-bold my-7">
                Gypsum and Paints
            </h1>
            <p class="w-full">
                We offer the finest types of gypsum by providing gypsum and painting works with the highest quality materials and varieties at suitable prices, executed by highly competent engineers.
            </p>
            </div>
        </div>
    </div>
</template>
<style>
.image-container {
    position: relative;
    overflow: hidden;
    background-color: white;
}

.icon {
    fill: #4699c1; /* Default color of the icon */
    transition: fill 0.3s ease; /* Add a smooth transition for color change */
}

.image-container::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('https://kaydc-eg.com/wp-content/uploads/2023/07/%D8%B4%D8%B1%D9%83%D8%A9-%D8%A7%D9%84%D9%85%D9%82%D8%A7%D9%88%D9%84%D8%A7%D8%AA-1024x666.jpg');
    background-position: center;
    background-size: cover;
    background-color: #00000066;
    background-blend-mode: overlay;
    opacity: 0;
    transition: opacity 0.3s ease; 
}

.image-container:hover .icon {
    fill: white; /* Change icon color on hover */
}

.image-container:hover::after {
    opacity: 1; /* Show the image on hover */
}

.image-container > * {
    position: relative;
    z-index: 1; /* Ensure other content remains on top */
}

.image-one::after {
    background-image: url('https://kaydc-eg.com/wp-content/uploads/2023/07/%D8%B4%D8%B1%D9%83%D8%A9-%D8%A7%D9%84%D9%85%D9%82%D8%A7%D9%88%D9%84%D8%A7%D8%AA-1024x666.jpg');
}
.image-two::after {
    background-image: url('https://www.homefix.bh/wp-content/uploads/2024/02/finishing-service-1.png');
}
.image-three::after {
    background-image: url('https://cdn.salla.sa/QzVpW/plMscfcJS4j8WpH7FFXgyrGCHv4EWA9TAXHS8nFJ.jpg');
}
.image-four::after {
    background-image: url('https://cdn.armut.com/images/services/48630-plumbing-and-electricity.jpg?v=1711573052027');
}
.image-five::after {
    background-image: url('https://alumital.org/wp-content/uploads/2018/03/%D8%A7%D8%B3%D9%82%D9%81-%D8%AC%D8%A8%D8%B3%D9%88%D9%86-%D8%A8%D9%88%D8%B1%D8%AF-1.jpg');
}
.image-six::after {
    background-image: url('https://maintenance-services-uae.com/wp-content/uploads/2020/05/%D8%AA%D8%B1%D9%83%D9%8A%D8%A8.jpg');
}
</style>