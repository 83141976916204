<template>
    <div>
        <footer class="bg-slate-50 py-8 md:py-16">
            <div class="container max-w-screen-xl mx-auto px-4">
                <div class="lg:flex flex-col md:flex-row text-center lg:text-start lg:justify-between">
                    <div class="mb-10 lg:mb-0 lg:w-1/4">
                        <img src="../assets/img/logos/logo.png" alt="Image" class="mb-5 mx-auto lg:mx-0 h-36">
                        <p class="font-normal text-gray-400 text-md">
                            <b>Nahil</b> is one of the classified and accredited establishments in the field of maintenance and implementation for all electrical, mechanical, and electronic works, and it started its activity since 2001.
                        </p>
                    </div>
                    <div class="space-y-4 mb-10 lg:mb-0 flex-grow">
                        <h4 class="font-semibold text-gray-500 text-lg mb-6">Our Services</h4>
                        <a href="#"
                            class="block font-normal text-gray-400 text-md hover:text-gray-700 transition ease-in-out duration-300"
                            >Contracting and Construction</a>
                        <a href="#"
                            class="block font-normal text-gray-400 text-md hover:text-gray-700 transition ease-in-out duration-300"
                            >Finishing Services</a>
                        <a href="#"
                            class="block font-normal text-gray-400 text-md hover:text-gray-700 transition ease-in-out duration-300"
                            >Interior and Exterior Design</a>
                        <a href="#"
                            class="block font-normal text-gray-400 text-md hover:text-gray-700 transition ease-in-out duration-300"
                            >Plumbing and Electrical Works</a>
                    </div>
                    <div class="space-y-4 mb-10 lg:mb-0 flex-grow">
                        <h4 class="font-semibold text-gray-500 text-lg mb-6">Quick Links</h4>
                        <router-link to="/about"
                            class="block font-normal text-gray-400 text-md hover:text-gray-700 transition ease-in-out duration-300"
                            >About Nahil</router-link>
                        <router-link to="/work"
                            class="block font-normal text-gray-400 text-md hover:text-gray-700 transition ease-in-out duration-300"
                            >Our Work</router-link>
                        <router-link to="/contact"
                            class="block font-normal text-gray-400 text-md hover:text-gray-700 transition ease-in-out duration-300"
                            >Contact Us</router-link>
                    </div>
                    <div class="space-y-4 flex-grow">
                        <h4 class="font-semibold text-gray-500 text-lg mb-6">Contact Us</h4>
                        <a href="#"
                            class="block font-normal text-gray-400 text-md hover:text-gray-700 transition ease-in-out duration-300"
                            > Kingdom of Saudi Arabia, Al-Madinah Al-Munawwarah
                            </a>
                        <a href="tel:+966557000098"
                            
                            class="block font-normal text-gray-400 text-md hover:text-gray-700 transition ease-in-out duration-300"
                            >
                            <span dir="ltr">+966 55 700 0098</span>
                            </a>
                            <a href="tel:+9660148260300"
                            
                            class="block font-normal text-gray-400 text-md hover:text-gray-700 transition ease-in-out duration-300"
                            >
                            <span dir="ltr">+966 014 8260 300</span>
                            </a>
                        <a href="mailto:info@nahil.sa.com"
                            class="block font-normal text-gray-400 text-md hover:text-gray-700 transition ease-in-out duration-300"
                            >info@nahil.sa.com</a>
                    </div>
                </div>
                <hr class="text-gray-300 mt-10">
                <p class="font-normal text-gray-400 text-md text-center mt-5">
                    All rights reserved 2024 &copy; <a href="https://nahil.sa.com">Nahil Contracting</a></p>
                    <p class="font-normal text-gray-400 text-md text-center mt-5 hidden">Website developed by <a href="https://eshaibani.com">Ebrahim Hasan</a></p>
            </div>
        </footer>
    </div>
</template>
